.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.login-form form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.login-form label {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.login-form label span {
    margin-bottom: 0px;
}

.login-form input[type="email"],
.login-form input[type="password"] {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    width: calc(100% - 20px);
    /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

}

.login-form .submit-button {
    cursor: pointer;
    background-color: #8E59FF;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 100%;
    /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.login-form .submit-button:hover {
    filter: brightness(95%);
}

.login-form .submit-button:active {
    filter: brightness(75%);
}

.inputText:focus {
    outline-color: #d3d3d3;
}

.login-form a {
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
}

.login-form a:hover {
    border-bottom: 2px solid #757575;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.logo-image {
    width: 300px; 
    height: auto; /* Mantém a proporção da imagem ao redimensionar */
    
}

/* Botão principal */
.swal2-styled.swal2-confirm {
    background-color: #8E59FF !important; /* Cor de fundo do botão principal */
    color: white !important; /* Cor do texto */
    border-radius: 5px !important; /* Borda arredondada */
}

.swal2-styled.swal2-confirm:focus {
    background-color: #8E59FF !important; /* Cor de fundo do botão principal ao focar */
    color: white !important; /* Cor do texto ao focar */
    box-shadow: none !important; /* Remover sombras */
    outline: none !important; /* Remover contorno de foco */
}

/* Botão secundário e terciário */
.swal2-styled.swal2-cancel, .swal2-styled.swal2-deny {
    background-color: #CCCCCC !important; /* Cor de fundo dos botões secundário e terciário */
    color: white !important; /* Cor do texto para botões secundário e terciário */
    border-radius: 5px !important; /* Borda arredondada */
}

.swal2-styled.swal2-cancel:focus, .swal2-styled.swal2-deny:focus {
    background-color: #CCCCCC !important; /* Cor de fundo dos botões secundário e terciário ao focar */
    color: white !important; /* Cor do texto ao focar */
    box-shadow: none !important; /* Remover sombras */
    outline: none !important; /* Remover contorno de foco */
}

.swal2-styled.swal2-confirm:hover, .swal2-styled.swal2-cancel:hover, .swal2-styled.swal2-deny:hover {
    filter: brightness(0.99); /* Aumenta o brilho ao passar o mouse */
}


@media (max-width: 768px) {

    .logo-image {
        width: 250px; 
        height: auto; /* Mantém a proporção da imagem ao redimensionar */
    }

    .login-form form {
        width: 75%;
        padding: 15px;
    }

    .login-form input[type="email"],
    .login-form input[type="password"] {
        font-size: 16px;
    }

    .login-form {
        height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

    

}