.control-panel-container {
    display: flex;
    align-items: flex-start; 
    justify-content: center;
    padding-top: 30px;
    height: 100vh;
    width: 100vw;
} 


.control-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 80vw; 
    box-sizing: border-box;
}

.control-panel h1 {
    margin-bottom: 20px;
}

.form-container {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
}

.control-panel form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
}

.control-panel label {
    font-size: 14px;
    color: #333333;
    display: flex;
    flex-direction: column;
}

.control-panel input[type="text"] {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
    width: calc(82% - 20px); 
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 18px;
    
}

.control-panel input[type="text-2"] {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
    width: calc(64% - 20px); 
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 18px;
}

.control-panel select {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 17%; 
    margin-top: 15px;
}

.submit-button {
    cursor: pointer;
    background-color: #CCCCCC;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 17%; 
    margin-top: 17px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
                0 3px 1px -2px rgba(0, 0, 0, 0.12), 
                0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
    filter: brightness(95%);
}

.submit-button:active {
    filter: brightness(75%);
}

.prompt-grid {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin: 0;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.grid-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.grid-cell {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    flex-basis: 49%; /* Isso fará com que cada célula ocupe cerca de metade da linha, com um pouco de espaço entre elas */
   overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
    height: 16px;
} 

.grid-cell-2 {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    flex-basis: 13%; /* Isso fará com que cada célula ocupe cerca de metade da linha, com um pouco de espaço entre elas */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
}

.grid-cell-3 {
    padding: 10px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 20px;
    box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0);
    flex-basis: 9%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
    display: flex; /* Ajuste aqui */
    align-items: center; /* Ajuste aqui */
    justify-content: center; /* Ajuste aqui */ 
    background-color: transparent; /* Ajuste aqui */
}

.grid-cell > button {
    height: 100%; /* Isso fará com que o botão preencha a altura disponível na célula */
    box-sizing: border-box; /* Isso garantirá que o padding e a borda do botão sejam incluídos em sua altura total, ajudando a evitar o desbordamento */
}

.tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%; 
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%; 
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.grid-cell:hover .tooltip {
    visibility: visible;
    opacity: 1;
}


.form-container, .prompt-grid {
    padding: 0;
}

.form-container-padding, .prompt-grid-padding {
    padding: 20px;
}

.grid-header {
    font-weight: bold;  /* Isso fará com que o texto do cabeçalho seja em negrito */
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    flex-basis: 49%; /* Isso fará com que cada célula ocupe cerca de metade da linha, com um pouco de espaço entre elas */
    color: #7b7b7b;
}

.grid-header-2 {
    font-weight: bold;  /* Isso fará com que o texto do cabeçalho seja em negrito */
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    flex-basis: 13%; /* Isso fará com que cada célula ocupe cerca de metade da linha, com um pouco de espaço entre elas */
    color: #7b7b7b;
}


.grid-header-3 {
    font-weight: bold;  /* Isso fará com que o texto do cabeçalho seja em negrito */
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    flex-basis: 8%; /* Isso fará com que cada célula ocupe cerca de metade da linha, com um pouco de espaço entre elas */
    color: #7b7b7b;
}

/* ... código anterior ... */

.prompt-tester {
    width: 100%;
    /* Remova a linha abaixo */
    /* padding: 20px; */
    border-radius: 10px;
    margin: 0;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}


.prompt-tester-padding {
    padding: 20px;
}

.input-container {
    display: flex;
    justify-content: space-between;
}

.tester-row {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 100%;
    margin-bottom: 10px;
} 


.tester-table {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tester-table > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

button {
    padding:0 10px;
    border: none;
    background-color: #CCCCCC;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
                0 3px 1px -2px rgba(0, 0, 0, 0.12), 
                0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

button:hover {
    filter: brightness(95%);
}

button:active {
    filter: brightness(75%);
}


.btn-button {
    padding: 0 10px;
    border: none;
    background-color: #007BFF; /* Alterada a cor para azul */
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
                0 3px 1px -2px rgba(0, 0, 0, 0.12), 
                0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-button:hover {
    filter: brightness(95%);
}

.btn-button:active {
    filter: brightness(75%);
}



.tester-buttons {
    display: flex;
    justify-content: flex-end;
}

.tester-buttons button {
    width: 180px; /* Adapte de acordo com o botão Salvar */
    height: 40px; /* Adapte de acordo com o botão Salvar */
}

.tester-buttons button:first-child {
    margin-top: 20px;
    margin-right:0 10px; /* Adiciona espaço à direita do primeiro botão */
}

.response-section {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.response-section-padding {
    padding: 20px;
}

.response-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.response-row textarea {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    flex-grow: 1; /* Isso fará com que o textarea ocupe 60% da linha */
    margin-right: 10px;
    height: 280px;
}

.response-image {
    width: 300px; /* Isso fará com que a imagem ocupe 30% da linha */
    height: 300px; /* Isso mantém a proporção da imagem */
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.custom-cell {
    max-height: 16px; /* ajuste este valor conforme necessário */
   
}

.lattl-ajust {
    position: absolute;
    top: 60px; /* Ajuste conforme necessário */
    right: 160px; /* Ajuste conforme necessário */
}

.lattl-ajust:hover {
    text-decoration: underline; /* Adiciona a linha sublinhada ao passar o mouse */
}

.logo-container-panel {
    /*display: flex;
    justify-content: center;
    margin-bottom: 30px;*/
    position: absolute;
    top: 7px;
    left: 70px;
    padding: 0;
    background-color: transparent;
}

.logo-image-panel {
    width: 140px; 
    height: auto; /* Mantém a proporção da imagem ao redimensionar */
    
}

/* CustomStyles.css */

.labelRight {
    display: flex;
    align-items: center;
  }
  
  .labelText {
    margin-left: 2px; 
  }
  
/* CustomStyles.css */

.formGroupSpacing {
    margin-top: 2px;
    margin-right: 30px;    
}

/* CustomStyles.css */
.labelRight {
    display: flex;
    align-items: center; /* Alinha os itens verticalmente */
  }
  
  .customLabel {
    display: flex;
    align-items: center; /* Alinha o checkbox e o texto horizontalmente */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    margin-right: 30px;
  }
  
  .customCheckbox {
    transform: scale(1.5); 
    margin-right: 8px; /* Espaço entre o checkbox e o texto */
  }
  
  .labelText {
    font-size: 18px;
    margin-left: 20px;
    color: #757575
  }
  

  


