.admin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;

    /* Move a div um pouco para cima */
    /*transform: translateY(-10%); */
}

/* Estilos para o avatar */
.avatar-container {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.user-avatar {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    /* Faz a imagem ficar redonda */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* Adiciona uma sombra sutil */
    cursor: pointer;
}


.admin-form form {
    display: flex;
    flex-direction: column;
    width: 340px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.admin-form .input-label {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.lblColor {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-left: 10px;
}


.admin-form .input-container-englob {
    display: inline-block;
    /* Faz com que os contêineres fiquem lado a lado */
    width: 100%;
    /* Define a largura para menos de metade para caberem na mesma linha */
    gap: 5px;
}

.admin-form .input-container-englob {
    display: flex;
    justify-content: space-between;
}

.admin-form .input-container div {
    flex: 1;
    /* Distribui o espaço igualmente */
    padding-right: 5px;
    /* Ajusta o espaçamento conforme necessário */
}

.admin-form input[type="text"],
.admin-form input[type="email"],
.admin-form input[type="tel"],
.admin-form input[type="password"] {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    width: 100% !important;
    height: 36px;
    /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.admin-form input[type="submit"] {
    cursor: pointer;
    background-color: #CCCCCC;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    height: 36px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    width: 100%;
    /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.admin-form input[type="submit"]:hover {
    filter: brightness(95%);
}

.admin-form input[type="submit"]:active {
    filter: brightness(75%);
}

.lattl-ajust {
    position: absolute;
    top: 60px;
    /* Ajuste conforme necessário */
    right: 160px;
    /* Ajuste conforme necessário */
}

.lattl-ajust:hover {
    text-decoration: underline;
    /* Adiciona a linha sublinhada ao passar o mouse */
}

.logo-container-form {
    background-color: transparent;
    /*position: absolute;*/
    /* flex-grow: 1;*/
    top: 27px;
    left: -50px;
    padding: 0;

}

.logo-image-form {
    width: 140px;
    height: auto;
}

.footer-btn {
    position: absolute;
    top: 7px;
    right: 20px;
    width: 160px;

}

.footer-btn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
    /* ajuste conforme a largura desejada */
    height: 30px;
    /* ajuste conforme a altura desejada */
    color: #757575;
    font-size: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    /* garante que não há sombra */
    transition: transform 0.3s;
    /* para um efeito suave ao pressionar */
}


.footer-btn button:hover .buttonRound,
.footer-btn button:hover span {
    filter: brightness(1.8);
    /* 1 é o normal, >1 torna mais claro */
}

.buttonRound {
    height: 30px;
    /* Ou o tamanho que você deseja */
    margin-right: 8px;
    /* Espaçamento entre o ícone e o texto */
}

.new-textarea {

    width: 90%;
    height: 120px;
    margin-top: 25px;
    font-family: Arial;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

}

.totens-container {
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
    /* Alinha os itens ao início do contêiner */
    gap: 20px;
}

.toten-form {
    /* Estilos para cada formulário individual */
    flex: 1;
    /* Faz cada formulário ocupar espaço igual dentro do contêiner */
    margin-right: 20px;
}

.add-toten-button-container {
    height: 100%;
    display: flex;
    align-items: center;
    /* Alinha o botão ao centro */
    justify-content: center;
    /* Centraliza o botão horizontalmente */
    margin-top: -35px;
}

.buttonGroupPerfil {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    gap: 20px;
}

.buttonMeta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 10px;
    gap: 10px;
}

.iconInsta {
    margin-right: 8px;
}

.statusIndicator {
    margin-top: 4px;
    margin-right: 15px;
}

.grid-cell-4 {
    padding: 0px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font-size: 10px;
    box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0);
    flex-basis: 3%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    display: flex;
    /* Ajuste aqui */
    justify-content: flex-end;
    /* Ajuste aqui */
    background-color: transparent;
    /* Ajuste aqui */
}

.iconBtn {
    font-size: 400px;
}

.googleButtonUser {
    display: inline-flex;
    align-items: center;
    background-color: #cccccc;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 140px;
    height: 36px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.googleButtonUserActivo {
    background-color: #8E59FF;
    /* Apenas substitui a cor de fundo */
}


.textAreaFundo {
    margin-top: 0px;
    margin-bottom: 10px;
    height: 90px
}



.categoryTitleH2 {
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
}

.categoryTitleH4 {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    color: #757575;
    font-weight: bold;
    font-size: 14px;
}

.header_form {
    left: 70px;
    right: 70px;
    top: 7px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    z-index: 10;
    background-color: #ffffff;
}

.radiobtn {
    right: 200px;
    width: 110px;
    display: flex;
    align-items: center;

}


.font-selection-container {
    display: flex;
    align-items: center;
    /* Alinha verticalmente os itens ao centro */
    justify-content: flex-start;
    /* Alinha os itens ao início do container */
    gap: 20px;
    /* Espaço entre os itens */
}

.font-example {
    font-size: 14px;
    /* Ajuste o tamanho da fonte conforme necessário */
}

.slctLabel {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    color: #757575;
    height: 25px;
    overflow-y: auto;
    max-width: 125px;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
    font-size: 14px;
    margin-left: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.btnColors {
    margin-top: 8px;
    margin-bottom: 8px;
    gap: 5px;
    margin-left: 0px;
    margin-right: 0px
}

.labelContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 9px;
}

.clnLink {
    color: #8E59FF;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
}

.iconClass {
    margin-right: 5px;
    /* Espaço entre o ícone e o texto */
    margin-left: 5px;
    font-size: 20px;
}

.labelColorLink {
    font-size: 14px;
    margin-left: 5px;

}

.labelColor {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.scrollable-container {

    border-radius: 5px;
    width: 100%;
    /* Largura completa dentro do seu contêiner pai */
    max-height: 90px;
    /* Altura máxima antes de começar a rolar */
    overflow-y: auto;
    /* Ativa a barra de rolagem vertical quando necessário */
    /*border: 1px solid #ccc; /* Adiciona uma borda para o contêiner, opcional */
    overflow-x: hidden;
    padding: 10px;
    /* Espaçamento interno para não colar no contorno */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Sombra leve para um efeito sutil */
    background: #fff;
    /* Cor de fundo */
    margin-bottom: 10px;
    border: '1px solid #dddddd'
        /* Espaço abaixo do contêiner */
}

.emoji {
    margin-left: 7px;
    /* Espaço entre o texto e o emoji */
    font-size: 22px;
    /* Tamanho maior para o emoji */
    vertical-align: middle;
    /* Alinha verticalmente com o texto */
}


.scrollable-container::-webkit-scrollbar {
    width: 8px;
    /* Largura da barra de rolagem */
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Cor de fundo da trilha */
    border-radius: 10px;
    /* Arredondamento da trilha */
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #888;
    /* Cor da barra de rolagem */
    border-radius: 10px;
    /* Arredondamento da barra de rolagem */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Cor da barra de rolagem ao passar o mouse */
}


@keyframes blink_2 {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.blink_2 {
    animation: blink 2s linear infinite;
    color: #757575;
}

.blink_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    /* Ou 'absolute', dependendo do layout */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    /* Transparência leve */
    font-size: 18px;
    z-index: 1000;
    /* Garante que apareça acima de outros elementos */
}

@media (max-width: 768px) {

    .logo-container-form {
        /* position: absolute; */
        margin-top: 7px;
        left: 55px;
        padding: 0;
        cursor: pointer;
    }

    .logo-image-form {
        width: 100px;
        height: auto;
    }

    .admin-form {
        overflow-x: hidden;
    }

    .admin-form form {
        width: 82.5%;
        padding: 15px;
       
    }


    .admin-form input[type="email"],
    .admin-form input[type="tel"],
    .admin-form input[type="text"] {
        font-size: 16px;
        margin-bottom: 3px;
    }

    .admin-form input[type="submit"] {
        margin-top: 15px;
    }

    .admin-form {
        height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

    .footer-btn span {
        display: none;
        /* Isso ocultará o texto "Configurações" */
    }

    .buttonRound {
        margin-top: 5px;
        margin-left: 30px;
        height: 40px;
    }

    .footer-btn {
        width: 50px;

    }

    .new-textarea {

        margin-top: 10px;
    }

    .totens-container {
        margin-top: -20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        width: 100%;
        overflow-y: auto;
        margin-bottom: 0px;
    }

    .toten-form {
        height: 90%;
        width: 90%;
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 20px;
        /* Faz os itens ocuparem toda a largura do contêiner */
    }


    .add-toten-button-container {
        margin-top: 0px;
        height: 80px;
        display: flex;
        align-items: center;
        /* Alinha o botão ao centro */
        flex-direction: column;
        /* Centraliza o botão horizontalmente */
    }

    .buttonGroupPerfil {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: -5px;
        margin-bottom: 0px;
        gap: 20px;
    }

    .googleButtonUser {
        min-width: 136px;
    }

    .googleButtonUserActivo {
        background-color: #8E59FF;
        /* Apenas substitui a cor de fundo */
    }

    .header_form {
        top: 0px;
        left: 55px;
        width: 85%;
        background-color: #ffffff;
    }

    .user-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        /* Faz a imagem ficar redonda */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        /* Adiciona uma sombra sutil */
        cursor: pointer;
    }

    .grid-cell-4 {
        width: 100%;
        margin-top: -10px;
    }

    .avatar-container {
        margin-bottom: 0px;
        margin-top: -5px;
    }

    .labelColor {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 2px;
    }

    .textAreaFundo {
        margin-top: 0px;
        margin-bottom: 5px;
        height: 90px
    }

    .btnColors {
        margin-top: 0px;
        margin-bottom: 5px;
        gap: 10px;
        margin-left: 0px;
        margin-right: 0px
    }

    .lblColor {
        margin-bottom: 4px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .slctLabel {
        height: 25px;
        overflow-y: auto;
        max-width: 125px;
        margin-left: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .font-selection-container {
        gap: 10px;
        /* Espaço entre os itens */
    }

    .blink_2 {
        padding: 60px;
        /* Adiciona espaço ao redor do texto para permitir a quebra de linha */
        box-sizing: border-box;
        text-align: center;
    }

    .radiobtn {
        width: 165px;
    }

    .clnLink {
        margin-top: 8px;
    }

    .labelColorLink {
        margin-left: 2px;
    }

    .iconInsta {
        margin-right: 4px;
    }

    .statusIndicator {
        margin-right: 10px;
    }

    .buttonMeta {
        margin-top: -2px;
    }

    .scrollable-container {
        margin-bottom: 5px;
        margin-top: -8px;
    }

    .labelContainer {

        margin-top: -10px;

    }

}