.swiper-button-next,
.swiper-button-prev {
    background: transparent;
    color: #757575;
    /* Cor das setas. */
    width: 50px;
    /* Largura das setas. */
    height: 50px;
    /* Altura das setas. */
    transform: scale(2.0);
}

.slideContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.categoryTitle-t {
    width: 100%;
    /* Estender o título para toda a largura do container */
    text-align: left;
    margin-top: 30px;
    /* Alinhar o texto à direita */
    margin-bottom: 1.5rem;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
}



.containerExter {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
    height: '100%'
}

html,
body,
#root {
    height: 100vh;
    margin: 0;
    padding: 0;
}

.swiper-container {
    height: 100vh;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    box-sizing: border-box;
    padding: 40px 60px;
    height: 100vh;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    height: 100%;
}

.gridItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ... outros estilos ... */

/* Cores do texto das categorias e dos botões */
.colorBlue {
    color: #3f729b;
    /* Azul Instagram */
}

.colorGreen {
    color: #4CAF50;
    /* Verde Instagram */
}

.colorYellow {
    color: #FECB2E;
    /* Amarelo Instagram */
}

.colorOrange {
    color: #F58529;
    /* Laranja Instagram */
}

.colorRed {
    color: #fa1427;
    /* Vermelho Instagram */
}

.colorPink {
    color: #fe8399;
    /* Rosa Instagram */
}

.colorDefault {
    color: #333;
    /* Cor de texto padrão */
}

.nicheButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #CCCCCC;
    /* Altera a cor da borda dos botões */
    font-size: 1em;
    text-align: center;
    padding: 100px;
    background: transparent;
    transition: all 0.3s ease;
    color: #757575;
    /* Altera a cor do texto dos botões */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 10px;
}

.googleButton {
    display: inline-flex;
    align-items: center;
    /* position:right;*/
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 150px;
    height: 36px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.googleButton:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.googleButton:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}

.googleButtonTxt {
    display: inline-flex;
    align-items: center;
    /* position:right;*/
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 150px;
    height: 36px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.googleButtonTxt:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.googleButtonTxt:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.lastButton {
    display: inline-flex;
    align-items: center;
    /* position:right;*/
    justify-content: center;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 112px;
    height: 36px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.lastButton:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.lastButton:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.my-swal-alert .swal2-checkbox input {
    position: absolute;
    background-color: #ffffff;
}

.my-swal-html-container {
    overflow-y: hidden !important;
    /* Oculta a barra de rolagem vertical */
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.blink {
    animation: blink 2s linear infinite;
    color: #757575;
}

.selectButton {
    display: block;
    margin-top: 20px;
    /* Adiciona margem acima do botão */
    width: 200px;
    height: 40px;
    margin: 20px auto;
    background-color: transparent;
    border: 2px solid #CCCCCC;
    border-radius: 5px;
    color: #757575;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.selectButton:hover {
    background: #f2f2f2;
    /* Muda a cor de fundo ao passar o mouse */
}

.container {
    display: flex;
    align-items: center;
    /* Centraliza verticalmente */
    gap: 10px;
    /* Adiciona espaço entre os elementos */
    margin-bottom: 10px;
}

.myCustomLabel {
    display: flex;
    align-items: center;
    /* Centraliza verticalmente os itens dentro da label */
    gap: 5px;
    /* Espaço entre a imagem e o texto */
}

.my-swal .swal2-image {
    width: 100%;
    /* Ocupar toda a largura do container */
    height: auto;
    /* Mantenha a proporção */
    max-height: 200px;
    /* Ajuste conforme necessário */
    object-fit: cover;
    /* Semelhante ao background-size: cover para <img> */
    display: block;
    margin: 0 auto;
    /* Centralizar horizontalmente */
}

.divTextArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    margin-top: 20px;
}

.textAreaSpace {
    flex: 1;
    width: 80%;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    padding: 10px 50px;
    margin-bottom: 10px;
}


.textAreaSpaceDin {

    width: 100%;
    /* Ocupa toda a largura do container pai */
    min-height: 80px;
    /* Altura mínima para a área de texto */
    padding: 10px 40px 10px 40px;
    /* Espaçamento interno para a área de texto */
    margin-top: 5px;
    /* Espaço entre a label e a textarea */
    border-radius: 10px;
    /* Bordas arredondadas para a textarea */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    /* Tamanho da fonte para a área de texto */
    font-family: Arial, sans-serif;
    /* Fonte para a área de texto */
}

.slidesContainer {
    border-radius: 10px;
    max-height: 480px;
    /* Altura máxima antes de começar a rolar */
    overflow-y: auto;
    /* Permite rolagem vertical quando o conteúdo exceder a altura máxima */
    padding: 10px;
    /* Espaçamento interno para não colar nos cantos */
    margin-top: 0px;
    /* Espaço no topo, se necessário */
    width: 80%;
    margin-bottom: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

}


.editable-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Alinha os elementos ao início do container */
    width: 100%;
    margin-bottom: 20px;
    /* Espaço entre cada bloco de edição, se necessário */
}

.lblEditText {
    color: #757575;
    font-size: 16;
    margin-left: 40;
}


.buttonGroup {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 20px;
}

.buttonGroupSave {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 20px;
}



/*.divTextArea {
    display: flex;
    width: 100%;
    height: 90%;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: #3f729b;
}

.textAreaSpace {
    flex: 1;
    width: 80%;
    font-size: 16px;
    border-color: transparent;
    box-sizing: border-box;
    border-width: 1;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    font-family: Arial;
    font-size: 16;
    padding-left: 50px;
    padding-right: 50px;
}

.buttonGroup {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 20px;
} */

.buttonGroupImg {
    width: 1184px;
    display: flex;
    justify-content: center;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
}

.btnNew {
    margin-top: 0px;
    width: 560px;
    margin-right: 0px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.userInfoDesktop {
    display: flex;
    align-items: 'center';
}

.userInfoMobile {
    display: none;
    /* Esconde por padrão */
}

.textContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    align-items: center;
}

.textHeadline {
    color: #757575;
}

.textareaContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 90%;
    height: 90%;
    justify-content: space-between;
}

.buttonStyle {
    background-color: #CCCCCC;
    color: white;
    width: 15%;
}

.carouselBox {
    background-color: #fff;
    padding: 20px;
    height: 500px;
    border-radius: 10px;
    width: 1100px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    /* Dividir horizontalmente */
    align-items: stretch;
    /* Ambas as colunas esticam para ocupar toda a altura */
    /* ... outras propriedades ... */
}

.carouselContent {

    display: flex;
    flex-direction: row;
    width: 100%;
}

.selectionArea {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.containerComboBox {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    gap: 40px;
}

.previewImage {
    display: flex;
    width: 500px;
    height: auto;
    justify-content: center;
    align-items: center;

}

.previewImage img {
    width: 300px;
    height: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}


.previewImageStories {
    display: flex;
    width: 400px;
    height: auto;
    justify-content: center;
    align-items: center;

}


.previewImageStories img {
    width: 200px;
    height: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.swal-input {
    border-radius: 5px;
    margin-top: 10px;
    width: 95%;
    height: 45px;
    margin-bottom: 5px;
    font-family: Arial;
    font-size: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 2px solid #767676;
    padding: 10px;
}

.colorGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: scroll;
    width: 80%;
    height: 210px;
    /* Ajuste conforme necessário */
    margin-bottom: 10px;
}

.btnSelect {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.btnSelectStories {

    margin-top: -40px;
}

.radioLay {
    margin-top: 15px;
    margin-right: 42px;
    max-width: 300px;
}

.radio-group-footer {
    position: absolute;
    margin-left: 72px;
    margin-top: 30px;
    width: 500px;
    z-index: 10;
    /* Outros estilos que desejar para alinhar o RadioGroup corretamente */
}


.uploadContainer {
    display: flex;
    width: 89.5%;
    height: 320px;
    /* Ajuste a altura conforme necessário */
    /* Apenas para visualização */
    gap: 20px;
}

.IAContainer {
    display: flex;
    width: 89.5%;
    height: 320px;
    gap: 20px;
}


.fileSizeNote {
    font-size: small;
}

/*.creditsNote {
    position:absolute;
    font-size:small;
    height: 20px;
    width: 150px;
    margin-right: 400px;
    margin-top: 300px;
    color: #fff;
    text-align:center;
    background-color: #8E59FF;
} */

.imagePreviewArea {
    flex: 1;
    /* Ocupa 50% da largura */
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    /* Para manter a imagem dentro da área de visualização */
    border-right: 0px solid #ccc;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Apenas para visualização */
}

.imagePreview {
    max-width: 100%;
    max-height: 85%;
    margin-top: 0px;
    object-fit: contain;
}


.imagePreviewOwn {
    max-width: 100%;
    max-height: 85%;
    margin-top: 0px;
    object-fit: contain;
}


.imagePreviewArea.selected {
    border: 5px solid #8E59FF;
    border-radius: 5px;
}


.fileInputContainer {
    margin-top: 0px;
    flex: 1;
    /* Ocupa os restantes 50% da largura */
    display: flex;
    align-items: center;
    /* Centraliza verticalmente */
    justify-content: center;
    /* Centraliza horizontalmente */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.fileInput {
    padding: 10px 20px;
    /* Ajuste o padding conforme necessário */
    cursor: pointer;
    /* Estilo do cursor para indicar que é clicável */
    /* Outras personalizações de estilo aqui */
}

.customFileButton {
    display: flex;
    align-items: center;
    /* Centraliza verticalmente */
    justify-content: center;
    /* Centraliza horizontalmente */
    gap: 10px;
    /* Espaço entre o ícone e o texto */
    background-color: #CCCCCC;
    color: white;
    border: none;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s;
    /* Adicione mais estilos conforme necessário */
}

.customFileButton:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.customFileButton:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}


.buttonStepper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 1180px;
    margin-left: 88px;
    margin-right: 88px;
    margin-bottom: 70px;
    bottom: 0;
}

.imagesContainer {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 330px;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 15px;
}


.divImagesAjs {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}

.divImagesAjs.selected {
    border: 3px solid #8E59FF;
    /* ou a cor que preferir */
    box-shadow: 0 0 10px #8E59FF;
    /* para adicionar um destaque extra */
}


.thumbnailImage {
    width: 220px;
    height: 220px;
    object-fit: cover;
    transition: opacity 0.3s;
}

.buttonFinal {

    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 25px;
    gap: 20px;
}

.buttonFinalStories {
    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 25px;
    gap: 25px;
}

.outerContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 95%;
    justify-content: space-between;
    align-items: center;

}

.innerContainer {
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 100%;
    gap: 150px;
    /* margin: 30px auto 20px auto;*/
    overflow-y: auto;
    overflow-x: hidden;
}



.floatingButtonsContainer {
    position: sticky;
    top: 30px;
    /* Ajuste conforme necessário */
    margin-left: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: -15px;

}

.floatingButtonsContainerStories {

    position: sticky;
    top: 30px;
    /* Ajuste conforme necessário */
    margin-left: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: -15px;

}

.floatingButton {
    margin-bottom: 15px;
    /* Espaçamento entre os botões */
    height: 60px;
    /* Tamanho do botão */
    width: 60px;
    /* Tamanho do botão */
    border-radius: 50%;
    /* Cria bordas arredondadas */
    background-color: #3f3d454a;
    /* Cor de fundo do botão */
    color: white;
    /* Cor do ícone ou texto */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
}




.innerContainerStories {
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 48%;
    height: 100%;
    overflow: auto;
}

.firstImage {
    /*margin: -290px 0 -130px 0; -210px 0 -130px 0;  Margem negativa no topo e espaço entre imagens */
    width: 375px;
    height: 600px;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.subsequentImage {
    margin: 0px 0 -130px;
    /* Espaço entre imagens */
    width: 375px;
    height: 600px;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.storyImage.firstImage {
    /*margin: -125px 0 0px 0;  Margem negativa no topo e espaço entre imagens */
    width: 253.82px;
    height: 451.25px;
}

.storyImage.subsequentImage {
    margin: 20px 0 0px;
    /* Espaço entre imagens */
    width: 253.82px;
    height: 451.25px;
}




/*.slideImageStories {
    margin: 0px auto;
    /* centraliza a imagem *
    width: 253.82px;
    height: 451.25px;
    /*375px; *
    object-fit: cover;
    /* garante que a imagem cubra toda a área da div mantendo sua proporção 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* adiciona sombra 
} */


.bottomLoading {
    bottom: 82.4%;
}

.bottom410 {
    bottom: 84.95%;
}

.bottom310 {
    bottom: 81%;
}

.captionContainer {
    width: 52%;
    height: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.blinking-text {
    animation: blink 1.2s linear infinite;
}

.loading-text-2 {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /* Ajuste o tamanho conforme necessário */
    height: 10%;
    /* Garantir que a área de texto ocupe toda a altura disponível */
    text-align: center;
    /* Ajuste conforme necessário */
}









/* ...resto do CSS... */

.footer-btn-wrapper button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 150px;
    /* ajuste conforme a largura desejada */
    height: 50px;
    /* ajuste conforme a altura desejada */
    color: #757575;
    font-size: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: none;
    /* garante que não há sombra */
    transition: transform 0.3s;
    /* para um efeito suave ao pressionar */
}

.footer-btn-wrapper {
    position: absolute;
    bottom: 22px;
    /* margem do rodapé */
    left: 50%;
    transform: translateX(-50%);
}


.footer-btn-wrapper button:hover .buttonIcon,
.footer-btn-wrapper button:hover span {
    filter: brightness(1.8);
    /* 1 é o normal, >1 torna mais claro */
}

.footer-btn-wrapper button:focus {
    box-shadow: none;
    /* remove o foco/sombra ao clicar */
}

.top-btn-wrapper button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 200px;
    /* ajuste conforme a largura desejada */
    height: 50px;
    /* ajuste conforme a altura desejada */
    color: #757575;
    font-size: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: none;
    /* garante que não há sombra */
    transition: transform 0.3s;
    /* para um efeito suave ao pressionar */
}

.top-btn-wrapper {
    position: absolute;
    top: 45px;
    /* margem do rodapé */
    left: 50%;
    transform: translateX(-50%);
}


.top-btn-wrapper button:hover .buttonIcon,
.top-btn-wrapper button:hover span {
    filter: brightness(1.8);
    /* 1 é o normal, >1 torna mais claro */
}

.top-btn-wrapper button:focus {
    box-shadow: none;
    /* remove o foco/sombra ao clicar */
}




/*.buttonIcon {
    height: 20px;
  
    width: auto;
    margin-right: 8px;
  
} */


.pexelsImg {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.image-container {
    position: absolute;
    top: 7px;
    left: 70px;
    padding: 0;
    background-color: transparent;
    /* border-color: #757575;
    border: 2px solid #CCCCCC;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);*/

}

.image-container_01 {
    position: absolute;
    left: 20px;
    padding: 0;
    background-color: transparent;
}



.slide-image {
    width: 140px;
    /* ou qualquer outro valor que funcione para você */
    height: auto;
}


.star-rating {
    font-size: 0;
    direction: rtl;
    margin-top: 15px;
}

.star-rating input[type="radio"] {
    display: none;
}

.star-rating label {
    font-size: 40px;
    padding: 0;
    margin-right: 15px;
    /* Espaçamento entre as estrelas */
    cursor: pointer;
    color: #e0e0e0;
    /* Cor das estrelas apagadas */
    transition: color 0.2s;
}

.star-rating label:hover,
.star-rating label:hover~label,
.star-rating input:checked~label {
    color: gold;
    /* Cor das estrelas acesas */
}

.my-textarea {

    width: 90%;
    height: 130px;
    margin-top: 25px;
    font-family: Arial;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #767676;
    padding: 10px;

}

.my-textarea2 {
    width: 100%;
    height: 65px;
    margin-top: 25px;
    font-family: Arial;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #767676;
    padding: 10px;
}

.menu-icon {
    font-size: 45px;
    margin-top: 5px;
    cursor: pointer;
    color: #757575;
}


.labelTopic {
    margin-top: 10px;

}

.labelTopicFirst {
    margin-top: 0px;

}

.inputFocus {
    border-color: transparent;
    font-size: 16px;
    height: 40px;
    border-width: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 96%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #dddddd;
}

.inputFocusFirst {
    border-color: transparent;
    font-size: 16px;
    height: 40px;
    border-width: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 96%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #dddddd;
}

.inputFocusAr {
    border-color: transparent;
    font-size: 16px;
    height: 40px;
    border-width: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 96%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 10px;
    border: 1px solid #dddddd;
}


.slcSlides {
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.slcSlidesLay {
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}


.inputFocusTxt {
    border-color: transparent;
    font-size: 16px;
    height: 37px;
    border-width: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;
    /*margin-bottom: 30px; */
    padding: 10px;
}

.inputAreaTxt {
    border-width: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-left: 0px;
    height: 60px;
    padding: 5px 0px 0px 10px;
}

.inputAreaTxtFullWidth {
    flex-grow: 1;
    /* Faz o textarea crescer para ocupar o espaço disponível */
    width: 100%;
    /* Sobrepor qualquer largura específica definida anteriormente */
}


.dividerFirst {
    margin-bottom: 10px;
    margin-left: 10px;
}

.dividerFirstStry {
    margin-bottom: 10px;
    margin-left: 10px;
}

input::placeholder {
    color: #999;
    /* Cor do placeholder */
    font-style: italic;
    /* Estilo da fonte */
    opacity: 0.6;
    /* Certifique-se de que o placeholder é sempre visível */
}

textarea::placeholder {
    color: #999;
    /* Cor do placeholder */
    font-style: italic;
    /* Estilo da fonte */
    opacity: 0.6;
    /* Certifique-se de que o placeholder é sempre visível */
}

.publicContainer {
    background-color: '#fff';
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    width: 540px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.buttonIcon {
    font-size: 25px;

}


.spinner_4 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    /* Cor de fundo do spinner */
    border-radius: 50%;
    border-top: 4px solid #8E59FF;
    /* Cor do spinner */
    width: 40px;
    margin-top: 0px;
    /* Tamanho do spinner */
    height: 40px;
    /* Tamanho do spinner */
    animation: spin 2s linear infinite;
}



.buttonContent {
    display: flex;
    /* Alinha o ícone e o texto na horizontal */
    align-items: center;
    /* Centraliza verticalmente o ícone e o texto */
    justify-content: center;
    margin-right: 0px;
}

.googleButtonNew {
    display: inline-flex;
    align-items: center;
    padding: 0px 15px;
    box-sizing: border-box;
    min-width: 150px;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;


    /* Adiciona um leve efeito de sombra ao botão */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.googleButtonNew:hover {
    /* Adiciona um efeito de hover, escurecendo levemente o botão */
    filter: brightness(95%);
}

.googleButtonNew:active {
    /* Adiciona um efeito de clique, escurecendo ainda mais o botão */
    filter: brightness(75%);
}

.categoryTitle-box {
    width: 100%;
    text-align: left;
    margin-bottom: 25px;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
}

.categoryTitleTema {
    margin-top: -20px;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 22px;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
}

.divMaster {
    display: 'flex';
    flex-direction: 'row';
    height: 500px;
    justify-content: 'space-between'
}


.outcome-grid-container {

    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 22.5px;
    margin: 0 auto;
    max-width: 100%;
    padding: 25px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 520px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}


.outcome-button {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 220px;
    padding: 16px;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.outcome-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}



.button-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
}

.emoji-icon {
    display: inline-block;
    font-size: 28px;
    margin-bottom: 4px;
}

.text-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #374151;
    line-height: 1.25;

}

.summary {
    font-size: 14px;
    color: #757575;
    line-height: 1.5;
    margin: 0;
}


.summaryText {
    font-size: 16px;
    color: #757575;
    line-height: 1.45;
    text-align: center;
}


.tabsContainer {
    margin-top: -30px;
    /* Ajuste este valor conforme necessário */
}

.tabsList {
    margin-left: 85px;
    margin-right: 85px;
}


.tabsListEnter {

    margin-left: 16.5px;
    margin-right: 16.5px;
}

.tabsContainer .chakra-tabs__tab {
    background: white;
    /* ou qualquer cor de fundo desejada */
    box-shadow: none;
    /* remove sombras */
    border: 1px solid #CBD5E0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    /* ou qualquer outra cor de borda desejada */
}

.tabsContainer .chakra-tabs__tablist {
    border-bottom: 1px solid #CBD5E0;
    position: relative;
    /* Necessário para o z-index funcionar */
    z-index: 100;
    /* Torna o tablist acima dos outros elementos */
}

.tabsContainer .chakra-tabs__tab--selected {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    /* para aplicar sombra apenas no tab ativo */
}

.tabsContainer .chakra-tabs__tab:not([aria-selected="true"]) {
    /* border: none;*/
    border-left: none;
    border-right: none;
    border-top: none;

    /* Remove a borda das abas não selecionadas */
}


/* CSS para a tab selecionada com imagem */
.tabsContainer .tab-with-image {
    color: #ffffff;
    background-color: #8E59FF;
    /* cor de fundo quando a imagem está selecionada */
}

/* CSS para a tab não selecionada */

.tabsContainerEnter .tab-without-image {
    color: #757575;
    color-scheme: purple;
}


.tabsContainerEnter {
    margin-top: 0px;
    margin-bottom: -15px;
    /* Ajuste este valor conforme necessário */
}


.tabsContainerEnter .chakra-tabs__tab {
    background: white;
    /* ou qualquer cor de fundo desejada */
    box-shadow: none;
    /* remove sombras */
    border: 1px solid #CBD5E0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* ou qualquer outra cor de borda desejada */
}

.tabsContainerEnter .chakra-tabs__tablist {
    border-bottom: 1px solid #CBD5E0;
    position: relative;
    /* Necessário para o z-index funcionar */
    z-index: 100;
    /* Torna o tablist acima dos outros elementos */
}

.tabsContainerEnter .chakra-tabs__tab--selected {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    /* para aplicar sombra apenas no tab ativo */
}

.tabsContainerEnter .chakra-tabs__tab:not([aria-selected="true"]) {
    /* border: none;*/
    border-left: none;
    border-right: none;
    border-top: none;
    /* Remove a borda das abas não selecionadas */
}

.firstEnter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
}


.btnPromo {
    margin-bottom: -80px;
    margin-top: 40px;
}

.btnPromo2 {
    margin-bottom: -55px;
    margin-top: 20px;

}

.stkBegin {

    margin-left: 10px;
    margin-bottom: 20px;

}


.grade {
    /* Grid básico */
    display: grid;
    grid-template-columns: repeat(0.5, 1fr);
    gap: 0rem;
    width: 100%;
    /* Card container */
    max-width: 31.2rem;
    /* equivalente ao max-w-2xl */
    max-height: 400px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 0.5rem;
}


/* Container principal das headlines */

.headline-grid-container {

    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 22.5px;
    margin: 0 auto;
    max-width: 100%;
    padding: 25px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 520px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.headline-button {
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 220px;
    padding: 16px;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.headline-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}


/* .headlines-section {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.headlines-section__item {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.headlines-section__item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}  */



/* Container do conteúdo dentro do botão */
.headlines-section__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


/* Título da headline */
.headlines-section__title {
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 1.2;
    color: #333;
}


/* Texto da headline */
.headlines-section__text {
    font-size: 1rem;
    color: #666;
    line-height: 1.4;
}


@media (max-width: 1280px) {

    /* Tablet */
    .divAreaImage {
        width: 100%;
    }

    .inputAreaTxtFullWidth {
        width: 40%;
    }

    .buttonGroupImg {
        margin-left: 66px;
        width: 88.2%;
    }

    .btnNew {
        justify-content: flex-start;
    }

    .inputFocusTxt {
        width: 50%;
    }

    .uploadContainer {
        width: 88%;
        display: flex;
        justify-content: flex-end;
        margin-left: -2px;
    }

    .IAContainer {
        width: 88%;
        display: flex;
        justify-content: flex-end;
        margin-left: -2px;
    }

    .imagesContainer {
        margin-left: 2px;
    }

    .divImagesAjs {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 16.8px;
        margin-right: 16.8px;
    }

    .buttonStepper {
        width: 986px;
        margin-bottom: 90px;
    }

    .captionContainer {
        width: 58%;
    }

    .radioLay {
        margin-top: 5px;
        /* margin-left: 150px; */
        margin-right: 35px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .headline-button {

        width: 200px;

    }


    .outcome-button {

        width: 240px;

    }

    .pexelsImg {

        bottom: -100px;

    }


    /* Título da headline */
    .headlines-section__title {
        font-size: 1rem;
    }

    /* Texto da headline */
    .headlines-section__text {
        font-size: 0.9rem;
    }

}

@media (hover: hover) and (pointer: fine) {
    .nicheButton:hover {
        background: #f2f2f2;
        /* Muda a cor de fundo ao passar o mouse */
    }
}

/*... Seus estilos de desktop já existentes ...*/

@media (max-width: 768px) {
    /* Ajustes para dispositivos móveis (portrait) */

    html,
    body,
    #root {
        height: 0;
    }

    .image-container {
        left: 55px;
    }

    .slide-image {
        width: 100px;
        /* ou qualquer outro valor que funcione para você */
        height: auto;
    }

    .swiper {
        height: 85vh;
        /* position: revert; */
        /* isso vai garantir que o swiper ocupe toda a altura da viewport */
    }

    .swiper-slide {

        padding-top: 80px;
        padding-bottom: 80px;
        height: 100%;
    }

    .swiper-pagination-bullet {
        display: none;
        /* Isso vai esconder as bolinhas do Swiper */
    }

    .gridContainer {
        grid-template-columns: 1fr;
        /* Ajusta o grid para ter apenas 1 coluna, assim, cada box fica alinhado na vertical. */
        grid-template-rows: 1fr 1fr 1fr 1fr;
        /* Como temos 4 elementos em uma "página", precisamos de 4 linhas. */

    }

    .stkBegin.mobile-zero-margin {
        margin-top: 0;
    }

    .categoryTitle {
        text-align: center;
        padding-bottom: 0px;
        width: 80%;
        margin: auto;
        font-size: 1rem;
        margin-top: 0px;
    }

    .categoryTitle-t {
        font-size: 14px;
        width: 80%;
        text-align: center;
        padding-bottom: 10px;
        color: #757575;
        font-weight: bold;
    }

    .categoryTitle-box {
        font-size: 14px;
        color: #757576;
        font-weight: bold;
        margin-bottom: 0px;
    }

    .nicheButton {
        font-size: 0.8em;
        width: 250px;
        height: 50px;
        padding: 30px 30px;
    }

    .selectButton {
        width: 150px;
        height: 36px;
        font-size: 14px;
    }

    h2 {
        font-size: 0.8em;
        /* Reduzir um pouco o tamanho da fonte para otimizar o espaço */
        margin: 0;
        /* Remover margens padrão */
    }

    label {
        font-size: 0.8em;
        /* Tamanho menor para labels */
        display: block;
        margin-top: 10px;
        /* Espaçamento entre elementos */
        margin-bottom: 5px;
    }

    .slideContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    /* Ajustando o input */
    .inputFocus {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: calc(100% - 9px);
        padding: 8px;
        margin-left: 0px;
        margin-right: 0px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #dddddd;
        font-size: 16px;
    }

    .inputFocusFirst {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: calc(100% - 9px);
        padding: 8px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #dddddd;
        font-size: 16px;
    }

    .inputFocusAr {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: calc(100% - 9px);
        padding: 8px;
        margin-left: 0px;
        margin-right: 0px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #dddddd;
        font-size: 16px;
        margin-bottom: 15px;
    }

    .textAreaSpace {
        width: 120%;
        appearance: none;
        font-size: 16px;
        border-color: transparent;
        border-width: 1;
        border-radius: 5px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    }

    .divTextArea {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }

    .tabsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 65px;
        margin-left: 0px;
        margin-right: 0px;
        /* Ajuste este valor conforme necessário */
    }

    .tabsList {

        width: 330px;

    }

    .inputAreaTxt {
        border-width: 1;
        border-radius: 5px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        width: 330px;
        margin-left: 0px;
        height: 60px;
    }

    .divAreaImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 0px;
    }

    .imagesContainer {
        width: 330px;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .imagesBank {
        height: 303px;
        margin: 50px auto 110px;
    }

    .imagesOwn {
        height: 351.5px;
        margin: 1.5px auto 110px;
        overflow-y: hidden;
    }

    .buttonGroupImg {
        width: 100%;
        display: grid;
        height: 60px;
        justify-content: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 10px;
        gap: 0px;

    }


    .buttonStepper {

        width: 330px;
        height: 35px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 75px;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .buttonStepper>button {
        /* ou a classe/especificador correto do botão */
        padding: 0px;
        margin-bottom: 20px;
        width: 20px;
        /* outras regras necessárias */
    }


    .uploadContainer {
        flex-direction: column-reverse;
        /* Elementos um sobre o outro */
        display: flex;
        /* Empilha os elementos verticalmente */
        gap: 10px;
        /* Espaçamento entre os elementos */
        height: 100%;
    }

    .IAContainer {
        flex-direction: column-reverse;
        /* Elementos um sobre o outro */
        display: flex;
        /* Empilha os elementos verticalmente */
        gap: 10px;
        /* Espaçamento entre os elementos */
        height: 100%;
        width: 100%;
    }

    .imagePreviewArea {
        flex: 4;
        height: 0%;
        border-right: 0px solid #ccc;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0);

    }

    .imagePreview {
        max-width: 95%;
        max-height: 95%;
        margin-top: 0px;
    }


    .imagePreviewOwn {
        max-width: 85%;
        max-height: 85%;
        margin-top: 0px;
    }


    .fileInputContainer {
        display: flex;
        flex: 1;
        height: 10%;
        width: 100%;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        /* Ocupa toda a largura disponível */
    }


    .fileInput {
        width: 100%;

    }

    .btnNew {
        margin-top: 13px;
        width: 330px;
        align-items: center;
        margin-right: 0px;
        display: flex;
        justify-content: flex-end;
        gap: 0px;
    }

    .customFileButton {

        height: 35px;

        /* Adicione mais estilos conforme necessário */
    }

    .radio-group-footer {
        position: absolute;
        margin-left: -140px;
        margin-top: 73%;
        margin-bottom: 0px;
        width: 190px;
        z-index: 10;
        /* Outros estilos que desejar para alinhar o RadioGroup corretamente */
    }

    .labelTopic {
        margin-top: 0;
    }

    .labelTopicFirst {
        margin-top: 0px;

    }

    .radioGroup {
        margin-right: 20px;
        /* Reduzir a margem entre as opções */
        display: flex;
        align-items: center;
        flex-direction: row
    }

    .top-btn-wrapper {
        position: absolute;
        top: 65px;
        /* margem do rodapé */
        left: 50%;
        transform: translateX(-50%);
    }


    .outerContainer {
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 105%;
        margin-top: 0px;

    }

    .innerContainer {
        width: 120%;
        box-sizing: border-box;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }

    button.googleButton {

        padding: 12px;
        margin-top: 20px;
        /* Espaçamento entre botão e input */
    }

    .googleButton {
        min-width: 120px;
        height: 32px;
        font-size: 12px;
    }


    button.googleButtonTxt {

        padding: 12px;
        margin-top: 20px;
        /* Espaçamento entre botão e input */
    }

    .googleButtonTxt {
        min-width: 98px;
        height: 32px;
        font-size: 12px;
    }

    .lastButton {
        min-width: 95px;
        height: 32px;
        font-size: 12px;
    }

    button.lastButton {

        padding: 12px;
        margin-top: 10px;
        /* Espaçamento entre botão e input */
    }

    .buttonFinal {
        width: 120%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 25px;
        gap: 10px;
    }

    .blink {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        /* Seu SwiperSlide pode ter um tamanho específico, se sim, defina aqui */
        width: 70%;
        height: 100%;
    }

    .swiper-button-prev {
        top: auto;
        bottom: 20px;
        left: 20%;
        transform: scale(1.5);
    }

    .swiper-button-next {
        top: auto;
        bottom: 20px;
        right: 20%;
        transform: scale(1.5);
    }

    .userInfoDesktop {
        display: none;
    }

    .userInfoMobile {
        display: block;
        margin-left: 10px;
    }

    .textHeadline {
        text-align: center;
    }

    .buttonGroup {
        width: 120%;
        justify-content: center;
        margin-bottom: 30px;
        gap: 15px;
        /* Centraliza os botões */
    }

    .buttonGroupSave {

        width: 120%;
        justify-content: right;
        margin: 0px;
        margin-bottom: 30px;
        /* Centraliza os botões */
    }

    .carouselBox {


        margin: 40px;
        width: 100%;
        height: 510px;
        padding-left: 0;
        padding-right: 0;

    }

    .carouselBoxStories {

        margin: 40px;
        width: 320px;
        height: 510px;
        padding-left: 0;
        padding-right: 0;

    }

    .carouselBox h2 {
        display: none;
    }

    .carouselContent {

        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .previewImage {
        display: flex;
        width: 68%;
        height: auto;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .containerExter {
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        justify-content: 'center';
        height: auto;
    }

    .previewImage img {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;

    }

    .selectionArea {
        width: 100%;
    }

    .containerComboBox {

        width: 90%;
        margin: 0;
        gap: 25px;

    }

    .stk {

        padding-bottom: 5px;
    }

    .slcSlidesLay {
        border: 1px solid #dddddd;
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .colorGroup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        height: auto;
        margin: 0;
        padding: 0;
    }



    .btnSelect button {
        margin: 0;

    }


    .container {
        margin-bottom: 0px;
    }

    .divImagesAjs {
        margin-top: 20px;
        margin-bottom: 0px;
    }



    .divImagesAjs.selected {
        /* Move o elemento 3px para cima */
        border: 3px solid #8E59FF;
        box-shadow: 0 0 10px #8E59FF;
    }

    .thumbnailImage {
        width: 200px;
        /* Ajuste conforme necessário para o tamanho desejado no mobile */
        height: 200px;
        /* Ajuste conforme necessário */
    }


    .carouselBox {
        margin-top: 40px;
    }


    .carouselBox {
        box-sizing: border-box;
    }


    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal {
        font-size: 12px;
        width: 85%;

    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }


    .my-swal-special {
        width: 85%;
    }

    .my-swal-alert .swal2-checkbox input {
        position: absolute;
        background-color: #ffffff;

    }

    .pexelsImg {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .star-rating label {
        margin-top: 0px;
        font-size: 40px;
        padding: 0;
        margin-right: 8px;
        /* Espaçamento entre as estrelas */
        cursor: pointer;
        color: #e0e0e0;
        /* Cor das estrelas apagadas */
        transition: color 0.2s;
        display: inline-block;
    }

    .star-rating {
        margin-top: 15px;
        font-size: 0;
        direction: rtl;
    }

    .my-textarea {
        margin-top: 10px;
    }

    .swal-input {

        width: 95%;
        height: 50px;
        border: 1px solid #767676;

    }

    .menu-icon {
        font-size: 35px;
    }

    .image-container_01 {
        position: absolute;
        left: 10px;
        padding: 0;
        background-color: transparent;
    }

    .divMaster {

        height: 122%;

    }


    .headline-grid-container {

        height: 77.5%;
        padding: 20px;
        width: 310px;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        /* Espaço menor entre os itens do grid */
        /*padding: 10px; /* Padding menor */
        margin-bottom: 0;
        margin-top: 20px;
    }


    .headline-button {

        margin-top: 10px;
        padding: 20px;
        width: 100%;
        height: 200px;
        /* Largura completa para se ajustar ao contêiner */
        /* Altura automática */
        font-size: 14px;
        /* Fonte menor */

    }


    .headline-button.second-button {
        height: 250px;
        /* Altura para o segundo botão no mobile */
    }


    .outcome-grid-container {

        height: 77.5%;
        padding: 20px;
        width: 310px;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        /* Espaço menor entre os itens do grid */
        /*padding: 10px; /* Padding menor */
        margin-bottom: 0;
        margin-top: 20px;
    }

    .outcome-button {

        margin-top: 10px;
        padding: 20px;
        width: 100%;
        height: 200px;
        /* Largura completa para se ajustar ao contêiner */
        /* Altura automática */
        font-size: 14px;
        /* Fonte menor */

    }

    .outcome-button.second-button {
        height: 250px;
        /* Altura para o segundo botão no mobile */
    }


    .categoryTitleTema {
        margin-top: 50px;
        width: 100%;
        padding-left: 0px;
        color: #757575;
        font-weight: bold;
        font-size: 16px;

    }

    .inputFocusTxt {
        border-color: transparent;
        font-size: 16px;
        height: 32px;
        border-width: 1;
        border-radius: 5px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        width: 170px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 20px;
        margin-bottom: 0px;
        padding: 10px;
    }

    .captionContainer {
        width: 165%;
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .floatingButtonsContainer {
        top: 20px;
        /* Ajuste conforme necessário */
        margin-left: 245px;
    }

    .floatingButtonsContainerStories {
        top: 30px;
        /* Ajuste conforme necessário */
        margin-left: 300px;
    }


    .storyImage.firstImage {

        width: 213.82px;

    }

    .storyImage.subsequentImage {
        width: 213.82px;
    }



    .btnSelect {
        margin-top: 0px;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-right: 12px;
        max-width: 350px;
    }

    .btnSelectStories {

        margin-top: 0px;
    }

    .radioLay {
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        flex: 1;
        display: flex;
        justify-content: baseline;
        align-items: center;
        min-width: 0;
        max-width: 100%;
    }



    .btnPromo {
        margin-bottom: -80px;
        margin-top: 27px;
    }

    .btnPromo2 {
        margin-bottom: -5px;
        margin-top: -25px;
    }

    .tabsContainer .chakra-tabs__tab:hover {
        /* box-shadow: none !important;*/
        background-color: transparent;
    }

    .tabsContainer .chakra-tabs__tab[aria-selected="true"] {
        background-color: #f3f3f3 !important;
        /* Ajuste para sua cor desejada */
        border-bottom: 0px
            /* Ajuste para sua cor desejada */
    }

    .firstEnter {
        margin-top: 0px;
        width: 125%;
    }

    .publicContainer {
        width: 320px;
        padding: 0px;
        border-radius: 10px;
        margin-right: 0px;
        padding-bottom: 5px;
        padding-top: 5px;

    }

    .tabsContainerEnter {
        margin-top: 0px;
    }

    .checkBoxEnter {
        margin-top: 0px;
        padding-right: 10px;
    }

    .dividerFirstStry {
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .dividerFirst {
        margin-bottom: 5px;
    }

    .tabsContainerEnter .chakra-tabs__tab[aria-selected="true"] {
        background-color: #f3f3f3 !important;
        /* Ajuste para sua cor desejada */
        border-bottom: 0px !important;
        /* Ajuste para sua cor desejada */
    }

    .slidesContainer {
        width: 120%;
    }

    .textAreaSpaceDin {
        padding: 10px 10px 10px 10px;
        /* Espaçamento interno para a área de texto */
    }

    .lblEditText {
        margin-left: 40;
    }

    .buttonStyle {
        width: 10%;
    }

    .previewImageStories {
        display: flex;
        width: 68%;
        height: auto;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

    }

    .previewImageStories img {
        width: 150px;
        height: auto;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }

    .innerContainerStories {

        width: 145%;
        overflow-x: hidden;

    }

      /* Título da headline */
      .headlines-section__title {
        font-size: 1rem;
    }

    /* Texto da headline */
    .headlines-section__text {
        font-size: 0.9rem;
    }

}