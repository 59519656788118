.history-screen {
    background-color: #fff;
    /* Cor de fundo da tela */
    display: flex;
    flex-direction: column;
    /* Elementos dispostos em coluna */
    align-items: center;
    /* Centraliza horizontalmente */
    padding-top: 20px;
    /* Espaçamento no topo */
    width: 100vw;
    /* Largura total da viewport */
}

.header_history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.image-container_history_01 {
    position: absolute;
    top: 0px;
    left: 20px;
    padding: 0;
    background-color: transparent;
}

.image-container_history_02 {
    position: absolute;
    top: 0px;
    left: 63px;
    padding: 0;
    background-color: transparent;
}

.logo {
    width: 140px;
    /* Largura do logo */
    height: auto;

    /* Altura automática mantém a proporção */
    cursor: pointer;
    /* Muda o cursor para indicar que é clicável */
}

.divAreaImageHistory {
    max-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

/* .images-grid { 
    display: grid;
    overflow-y: auto;
    border-radius: 10px;
    grid-template-columns: repeat(4, 0fr);
    grid-gap: 0px 30px;
    /* Espaçamento entre as imagens *
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 auto;
    max-width: 100%;
    max-height: 70%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}  */

.images-grid {
    display: grid;
    overflow-y: auto;
    border-radius: 10px;
    grid-template-columns: repeat(4, minmax(250px, 1fr));
    /*repeat(auto-fill, minmax(250px, 1fr));*/
    /* Isso garante que as colunas sejam distribuídas uniformemente */
    grid-gap: 0px 30px;
    /* Espaçamento entre as imagens */
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 auto;
    width: 1300px;
    height: 300px;
    height: 70%;
    max-width: 100%;
    /* Define uma largura máxima para a grid */
    /* min-height: 600px; */
    /* Define uma altura mínima para garantir um layout consistente */
    max-height: 70%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}




.date-label {
    position: absolute;
    bottom: 5px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 12px;
    width: 200px;
    text-align: center;
}

.categoryTitleHistory {
    width: 100%;
    margin-top: 15px;
    padding-left: 0px;
    margin-bottom: 10px;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
    opacity: 0;
    /* Inicia invisível */
    animation: fadeIn 0.05s linear 0.05s forwards;
}





.buttonGroupImgHist {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: -10px;
    margin-bottom: 10px;
    gap: 50px;
}

.positionRadio {
    margin-top: 5px;
    color: #757575
}


/* Contêiner Principal para Cada Post */
.post-container {
    display: flex;
    flex-direction: column;
    /* Organiza imagem e botões verticalmente */
    align-items: center;
    /* Centraliza o conteúdo no eixo horizontal */
    margin-bottom: 20px;
    /* Espaçamento inferior para cada post */
    position: relative;
    /* Mantém o contêiner posicionado */
}

/* Contêiner de Status */
.status-container {
    display: flex;
    /* Alinha bolinha e texto lado a lado */
    align-items: center;
    /* Alinha verticalmente */
    justify-content: left;
    margin-top: 20px;
    margin-bottom: 0px;
    /* Espaço entre o status e a imagem */
    width: 90%;
}

/* Indicador de Status (Bolinha) */
.status-indicator {
    width: 12px;
    /* Diâmetro da bolinha */
    height: 12px;
    /* Diâmetro da bolinha */
    border-radius: 50%;
    /* Torna a bolinha redonda */
    display: inline-block;
    margin-right: 8px;
    /* Espaço entre a bolinha e o texto */
}

/* Texto de Status */
.status-text {
    font-size: 14px;
    /* Tamanho da fonte do status */
    color: #333;
    /* Cor do texto */
}

/* Contêiner para Imagens */
.image-wrapper {
    position: relative;
    cursor: pointer;
    margin: 10px;
    width: 250px;
    /* Ajuste o tamanho da imagem conforme necessário */
    height: auto;
}

/* Contêiner para os Botões */
.buttons-container {
    display: flex;
    /* Alinha os botões lado a lado */
    justify-content: center;
    /* Centraliza os botões no contêiner */
    margin-top: 0px;
    /* Espaçamento superior para separar da imagem */
    gap: 10px;
    /* Espaçamento entre os botões */
}

/* Botões de Ação */
.action-button {
    height: 50px;
    /* Altura do botão */
    width: 50px;
    /* Largura do botão */
    border-radius: 50%;
    /* Botão redondo */
    background-color: #3f3d454a;
    /* Cor de fundo */
    color: white;
    /* Cor do texto ou ícone */
    border: 2px solid #fff;
    cursor: pointer;
    /* Cursor indicando ação */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    /* Sombra para os botões */
    transition: background-color 0.3s ease;
}

/* Efeito de Hover nos Botões */
.action-button:hover {
    background-color: #555;
    /* Cor de fundo ao passar o mouse */
}

/* Imagem */
.history-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Sombra */
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Fundo escurecido */
    display: flex;
    /* Flexbox para centralizar */
    justify-content: center;
    /* Centraliza horizontalmente */
    align-items: center;
    /* Centraliza verticalmente */
    color: white;
    font-size: 24px;
    font-weight: bold;
    transition: opacity 0.3s;
    opacity: 0;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Adicionando a classe de atualização */
.overlay.updating {
    animation: blink 1.5s infinite;
    opacity: 1;
    /* Deixa visível a overlay enquanto pisca */
}




/* HistoryScreen.css */
.post-container .edit-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
}

.post-container.editing .edit-mask {
    opacity: 1;
}

.history-image {
    position: relative;
}


.spinner {
    position: absolute;
    /* Necessário para centralizar */
    top: 50%;
    /* Centraliza verticalmente */
    left: 50%;
    /* Centraliza horizontalmente */
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #8E59FF;
    width: 40px;
    /* Largura do spinner */
    height: 40px;
    /* Altura do spinner */
    transform: translate(-50%, -50%);
    /* Ajusta para centralizar no centro exato */
    animation: spin 2s linear infinite;
    /* Animação de rotação */
}


.no-data-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 40px;
    color: #757575;
    transform: translate(-50%, -50%);
}

.stories-size {
    height: auto;
    /* Defina a altura que você deseja para os stories */
    width: 185px;
    /* Defina a largura dos stories */
}


@media (max-width: 1280px) {


    .images-grid {
        width: 1150px;
        height: 80%;
        grid-gap: 0px 10px;
        margin-bottom: -40px;
    }

    .image-wrapper {

        width: 220px;

    }


    .stories-size {

        width: 200px;

    }

    .status-container {

        width: 86%;

    }

    .date-label {
        font-size: 10px;
    }

    .overlay {

        font-size: 20px;
    }

}


@media (max-width: 768px) {

    .logo {
        width: 100px;
        /* Largura do logo */
    }

    .divAreaImageHistory {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        margin-top: 0px;
        width: 100%;
    }


    .images-grid {
        padding-left: 25px;
        padding-right: 25px;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        width: 330px;
        max-height: 100%;
        margin-bottom: -40px;
    }


    .categoryTitleHistory {
        width: 80.5%;
        margin-left: 0px;
        margin-top: 53px;
        margin-bottom: 0px;
        color: #757575;
        font-weight: bold;
        font-size: 16px;
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

    .spinner {

        left: 45%;
        margin-top: 350px;

    }

    .image-container_history_02 {

        left: 48px;

    }

    .buttonGroupImgHist {
       
        width: 77.5%;
        margin-right: 55px;
        margin-left: 55px;
        margin-top: 20px;
        gap: 22px;

    }

    .positionRadio {
        margin-top: -7.5px;
    }

    .slcSlidesLayHis {
        width: 100px;
    }

    .status-container {
        width: 78%;
    }

    .stories-size {
        width: 180px;
        /* Altere para o tamanho desejado */
    }

}