.image-container-drawer {
    position: absolute;
    top: 25px;
    left: 35px;
    padding: 0;
    background-color: transparent;

}

.slide-image-drawer {
    width: 240px;
    height: auto;
}

.list-item-icon {
    font-size: 35px;
    margin-right: 8px;
}

.new-textarea{
    border: 1px solid #767676;
    height: 130px;
    padding: 10px;
}

@media (max-width: 768px) {


    .list-item-icon {
        font-size: 30px;
        margin-right: 8px;
    }

    .list-item {
        font-size: 14px;
        /* Reduzir o tamanho do texto nos itens da lista */
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal {
        font-size: 12px;
        width: 85%;

    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

}