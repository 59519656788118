.resetpasswordrequest-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.resetpasswordrequest-form form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    background-color: #ffffff;
}

.resetpasswordrequest-form label {
    font-size: 14px;
    color: #333333;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.resetpasswordrequest-form label span {
    margin-bottom: 0px;
}

.resetpasswordrequest-form input[type="email"], .resetpasswordrequest-form input[type="password"] {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    width: calc(100% - 20px);  /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
   
}

.resetpasswordrequest-form .submit-button {
    cursor: pointer;
    background-color: #8E59FF;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 100%;  /* Reduce a largura em 20px para considerar o padding */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
                0 3px 1px -2px rgba(0, 0, 0, 0.12), 
                0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.resetpasswordrequest-form .submit-button:hover {
    filter: brightness(95%);
}

.resetpasswordrequest-form .submit-button:active {
    filter: brightness(75%);
}

.textImput:focus {
    outline-color: #d3d3d3;
}

.resetpasswordrequest-form a {
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
}

.resetpasswordrequest-form a:hover {
    border-bottom: 2px solid #757575;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.logo-image {
    width: 300px; 
    height: auto; /* Mantém a proporção da imagem ao redimensionar */
    
}


@media (max-width: 768px) {

    .resetpasswordrequest-form form {
        width: 75%;
        padding: 15px;
    }

    .logo-image {
        width: 250px; 
        height: auto; /* Mantém a proporção da imagem ao redimensionar */
        
    }

    .resetpasswordrequest-form input[type="email"]
     {
        font-size: 16px;
     }

     .resetpasswordrequest-form {
        height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .myCustomTitle {
        font-size: 18px;
    }

    .my-swal-alert {
        font-size: 14px;
        width: 85%;
    }

}