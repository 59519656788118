.logo-container-form-screen {
    display: flex;                /* Usa flexbox para alinhar o conteúdo */
    justify-content: center;      /* Centraliza horizontalmente */
    align-items: center;          /* Centraliza verticalmente */
    height: 100vh;                /* Faz o container ter a altura total da tela */
    background-color: transparent;      /* Define a cor de fundo para preto */
}


.logo-image-form-screen {
    width: 240px;
    height: auto;
}

  
  .scrollable-container-pop-up {
    max-height: 250px; /* Mantém o tamanho definido anteriormente */
    overflow-y: auto; /* Habilita a rolagem vertical */
    -webkit-overflow-scrolling: touch; /* Rolagem suave em iOS */
    touch-action: auto; /* Garante que os eventos de toque sejam reconhecidos */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .account-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .account-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .account-username {
    flex-grow: 1;
    text-align: left;
  }
  
  .account-radio {
    margin-left: 30px;
  }


  @media (max-width: 768px) {

    .logo-container-form-screen {
               
        height: 80vh;                
        
    }

  }
  
  