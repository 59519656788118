.landscape-warning {
    display: flex;
    flex-direction: column; /* Adicione esta linha */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 9999;
    justify-content: center; /* Descomente essa linha */
    align-items: center; /* Descomente essa linha e defina como center */
    font-size: 18px;
    color: white;
}

  .landscape-warning h1, .landscape-warning h2, .landscape-warning p {
    margin-bottom: 20px; /* ou qualquer outro valor que você prefira */
}