.terms-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    overflow-y: hidden;
}

.logo-container-form-service {
    background-color: transparent;
    top: 27px;
    left: -50px;
    padding: 0;

}

.header_form_service {
    left: 70px;
    right: 70px;
    top: 7px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    z-index: 10;
    background-color: #ffffff;
}

.textService {

    width: 80%;
    height: 70%;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow-y: auto;
}


.categoryService {
    width: 100%;
    padding-left: 160px;
    margin-bottom: 10px;
    color: #757575;
    font-weight: bold;
    font-size: 24px;
    opacity: 0; /* Inicia invisível */
    animation: fadeIn 0.05s linear 0.05s forwards;
}


.textService h1 {
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    font-weight: bold;
}

.textService h2 {
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    font-weight: bold;
}

.textService h3 {
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
}

.textService p {
    margin-top: 1em;
    margin-bottom: 1em;
}

.textService ul {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 40px;
}

.textService a {
    color: blue;
    text-decoration: underline;
}

@media (max-width: 768px) {

    .categoryService{
        margin-top: 0px;
        font-size: 16px;
        padding-left: 60px;
    }

    .textService {
       height: 75%;
       margin-bottom: 10px;
    }

    .terms-service {
        height: 85vh;
    }

}
