.pci-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: #fff;

}

.publicContainer_pci {
  background-color:#fff;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 640px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.inputFocus_pci {
  border-color: transparent;
  font-size: 16px;
  height: 40px;
  border-width: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 96.8%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #dddddd;
}

.inputFocus_pci_02 {
  border-color: transparent;
  font-size: 16px;
  height: 40px;
  border-width: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 107.5%;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #dddddd;
}

.labelTopic {
  margin-top: 0px;
}

.image-container_pci {
  position: absolute;
  top: 0px;
  left: 20px;
  padding: 0;
  background-color: transparent;
}

.image-container_pci_02 {
  position: absolute;
  top: 0px;
  left: 63px;
  padding: 0;
  background-color: transparent;
}

.divAjust {
  display: flex;
  flex-direction: row;
  gap: 30px
}

.divAjust_2 {
  display: flex;
  flex-direction: row;
  gap: 30px
}

.markdownSpace {
  padding: 1em;
  background: #fff;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  flex: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  width: 80%;
}

.categoryTitle-boxpci {
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  color: #757575;
  font-weight: bold;
  font-size: 24px;
}

.divbtnPCI {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
}

input::placeholder {
  color: #999;
  /* Cor do placeholder */
  font-style: italic;
  /* Estilo da fonte */
  opacity: 0.6;
  /* Certifique-se de que o placeholder é sempre visível */
}


.divTextAreaPCI {
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.buttonGroupPCI {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 20px;
}


.custom-markdown li {
  line-height: 1.2;
  /* Ajusta a altura da linha para parágrafos e listas */
  margin-bottom: 5px;
  /* Ajusta o espaço inferior */
}


.custom-markdown p {
  line-height: 1.2;
  /* Ajusta a altura da linha para parágrafos e listas */
  margin-bottom: 5px;
  /* Ajusta o espaço inferior */
}


.custom-markdown ol {
  line-height: 1.2;
  padding-left: 20px;
  /* Recuo para listas */
}


.custom-markdown ul {
  line-height: 0.5;
  padding-left: 20px;
  /* Recuo para listas */
}

.custom-markdown h1,
.custom-markdown h2,
.custom-markdown h3,
.custom-markdown h4 {
  margin-top: 10px;
  /* Espaço superior dos títulos */
  margin-bottom: 10px;
  /* Espaço inferior dos títulos */
  line-height: 1.2;
  /* Altura da linha para títulos */
}

.markdownSpace p {
  margin: 0.5em 0;
}

.markdownSpace strong {
  font-weight: bold;
}


@media  (max-width: 1280px){

  .inputFocus_pci_02 {
    width: 96.5%;
  }  
}


@media (max-width: 768px) {

  .publicContainer_pci {
    width: 120%;
  }

  .divAjust {
    margin-top: 14px;
    flex-direction: column;
    gap: 9px
  }

  .divAjust_2 {
    margin-top: 10px;
    flex-direction: column;
    gap: 9px
  }

  .inputFocus_pci_02 {

    width: 97%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #dddddd;

  }

  .inputFocus_pci {

    width: 97%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #dddddd;

  }


  .divTitle {
    margin-top: 0px;
  }

  .categoryTitle-boxpci {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    color: #757575;
    font-weight: bold;
    font-size: 14px;
    margin-top: -15px;
  }

  .divbtnPCI {
    display: flex;
    justify-content:flex-end;
    margin-right: 10px;
    margin-top: 30px;
  }

  .markdownSpace {
    
    width: 120%;
    appearance: none;
    font-size: 16px;
    border-color: transparent;
    border-width: 1;
    border-radius: 5px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .divTextAreaPCI {
    display: flex;
    width: 100%;
    height: 101%;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .buttonGroupPCI {
    width: 120%;
    margin-top: 36px;
    margin-bottom: 0px;
  }



}